.bars {
  display: flex;
  font-size: 25px;
  margin-left: 30px;
  margin-right: 10px;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 0px;
}
.logo {
  font-size: 24px;
}
.sidebar {
  background: rgb(217 230 228);
  color: #000;
}
.firstsidebar-title {
  padding: 10px 4px;
  color: #000;
  display: flex;
  gap: 15px;
  cursor: pointer;
  text-decoration: none;
  z-index: 10;
}
.firstsidebar-item{
  text-decoration: none;
}
.firstsidebar-item:hover {
  background-color: #d3dedc;
  border-radius: 5px;
}
.icon,
.link_text {
  font-size: 17px;
  white-space: nowrap;
  text-decoration: none;
  color: #000;
}
.link_text.active {
  font-weight: 600;
}
.link_text:hover {
  font-weight: 600;
  color: #000;
}
.tooltip {
  position: absolute;
  background-color: #333333e7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
div:hover .tooltip {
  /* margin-left: 35px; */
  opacity: 1;
  transform: translateX(30px) translateY(-5px);
}
.sidebar-child {
  background-color:rgb(236, 244, 243);
  color: #000;
}