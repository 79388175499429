.gtm-sidebar-header {
  height: 50px;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  padding: 10px;
}

.sidenav {
  width: 50%;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 59px;
  right: 0;
  bottom: 10px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
}

.sidenav .closebtn {
  text-decoration: none;
  color: black;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  padding: 0px;
}
.side_bar_heading {
  font-size: var(--sub-heading-font-size);
  display: flex;
  margin-left: 10px;
  font-weight: var(--heading-font-weight);
  text-wrap: nowrap;
}
.gtm-sidebar-body .row .inputField {
  display: flex;
  align-items: center;
  /* margin: auto; */
  margin-left: 3px;
  width: 49%;
  margin-bottom: 20px;
}
.status-select {
  width: 100%;
  text-align: left;
  font-size: var(--sub-heading-font-size);
}
.gender {
  margin-left: 35px;
}
.reporting {
  margin-left: 18px;
}
.status {
  margin-left: 44px;
}
.bi {
  margin-left: 18px;
}
.inputField {
  display: flex;
  align-items: center;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .sidenav {
    width: calc(100% - 50px);
  }
}