:root {
  /* Global Font Sizes*/
  --menu-heading-font-size: 16px;
  --menu-font-size: 14px;
  --main-heading: 24px;
  --sub-heading-font-size: 16px;
  --heading-font-weight: bold;
  --text-font-size: 16px;
  --captions-font-size: 12px;
  --task-title-font-size: 24px;
}
