.swal2-icon {
    height: 30px;
    width: 30px;
    margin: 1em auto 0.1em;
    border: 0.2em solid rgba(0,0,0,0);
}
.swal2-icon-content {
    font-size: 2em !important;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
    left: 0.21em;
    transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
    left: 0.2em;
    transform: rotate(-45deg);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    display: block;
    position: absolute;
    top: 0.85em;
    width: 1.5375em;
    height: 0.2125em;
    border-radius: 0.125em;
    background-color: #f27474;
}
.swal2-popup{
    width:400px;
    padding:0 0 1em;
}
.swal2-actions{
    justify-content: flex-end;
    width:90%;
}

.swal2-title{
   font-size:20px;
}


.swal2-styled.swal2-confirm{
    padding: 0px;
    width:100px;
    height:40px;

}
.swal2-styled.swal2-cancel{
    padding: 0px;
    width:100px;
    height:40px;
    color:black;
    border:thin ridge;
}
.swal2-html-container {
    font-size:16px;
 }

.swal2-container {
    z-index:9999;
}