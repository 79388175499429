.dashboard-container {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-card-container {
  height: 150px;
  width: 150px;
  /* background-color: red; */
  margin: 20px;
}
.dashboard-card-img img {
  width: 80px;
  height: 80px;
}
.dashboard-card-name,
.custom-dashboard-link {
  text-decoration: none;
}
.dashboard-card-name {
  color: black;
  font-size: var(--sub-heading-font-size);
  font-weight: var(--heading-font-weight);
}