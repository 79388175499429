.text-center {
    width: 100%;
    position: relative;
    height: 100%;
}

.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));  
    right: calc(50% - (58px / 2));
    color: #0A5F59;
}

#backdrop {
  position:absolute;
  top:0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgb(0,0,0,0.2);
}